import React, { useEffect, useState } from 'react'
import UserList from '../../../components/chat/UserList';
import { base64Encode, isBase64 } from '../../../helpers/base64';
import { useParams,useLocation  } from "react-router-dom";
import Cookies from "js-cookie";
import { getTeacherList } from '../../../service/studentChat';
import LoginExpire from '../../../components/LoginExpire';


export default function TeacherList() {

  // let data = "9486|VZoVAagTUk9M2ZtiWFNALSjF7U7WJueZskbP515L7838ad3d";
  // const ecode = base64Encode(data);
  // console.log('encode-- '+ecode)
  const { search } = useLocation();
  const [teacherslist, setTeacherList ] = useState([]);
  const [login, setLogin ] = useState(true);
  useEffect(()=>{
    const params = new URLSearchParams(search);
  const dataParam = params.get('data');
  localStorage.removeItem("base64token")
  localStorage.setItem("base64token",dataParam)
  const isValidBase64 = isBase64(dataParam)
  let decodebase = isValidBase64 ? atob(dataParam) : null;
  Cookies.set("token",decodebase)
  localStorage.setItem("token",dataParam)
    const getTeachers = async ()=>{
      const get = await getTeacherList();
      if(get.status == "error"){
        window.location.reload();
        setLogin(false)
      }
      setTeacherList(get.message)
    }
    getTeachers();
  },[login])
  return (
    <>
      {
        login ? <UserList list={teacherslist} /> : <LoginExpire />
      }
    </>
  )
}
